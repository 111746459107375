import React, { useContext, useEffect } from "react"
import get from "lodash/get"
import InternalLink from "./InternalLink"
import Wrapper from "./Wrapper"
import ExternalSVG from "../images/arrow-up-right.inline.svg"
import StoreContext from "../context/StoreContext"
import { defaultLang } from "../../prismic-configuration"
import getTranslations from "../translations"

const PushNav = props => {
  const { primaryNav, secondaryNav, lang } = props
  const t = getTranslations(lang)
  const context = useContext(StoreContext)
  const { setOverlayVisibility } = context
  // useEffect( () => setOverlayVisibility(true), [setOverlayVisibility] );
  // useEffect( () => () => setOverlayVisibility(false), [setOverlayVisibility] );
  return (
    <nav className="push-nav">
      {secondaryNav && (
        <Wrapper>
          <ul className="lh--s">
            <li className="fs--l">
              <InternalLink uid={`${lang === defaultLang || !lang ? "/shop/" : lang + "/shop/"}`} type="shop" title="Shop">
                Shop
              </InternalLink>
            </li>
            <li className="fs--l">
              <InternalLink
                uid={lang === defaultLang || !lang ? "/stofproever/" : lang + "/fabric-samples/"}
                type="samples"
                title={t.secondary_nav.samples}
              >
                {t.secondary_nav.samples}
              </InternalLink>
            </li>
            {secondaryNav.map((node, i) => {
              const link = get(node, "secondary_link")
              const title = get(link, "title[0].text")
              const uid = get(link, "_meta.uid")
              const type = get(link, "_meta.type")
              const lang = get(link, "_meta.lang")

              if (uid && type) {
                return (
                  <li className="fs--l" key={i}>
                    <InternalLink uid={uid} type={type} title={title} lang={lang}>
                      {title}
                    </InternalLink>
                  </li>
                )
              }
            })}
            <li className="fs--l">
              <InternalLink uid={lang === defaultLang || !lang ? "stylerad" : "style-advice"} type="page" title={t.secondary_nav.contact} lang={lang}>
                {t.secondary_nav.contact}
              </InternalLink>
            </li>
          </ul>
          <ul>
            <li className="fs--base lh--m">
              <a target="_blank" href="https://anddrapeguide.crisp.help/da/">
                FAQ <ExternalSVG />
              </a>
            </li>
            <li className="fs--base lh--m">
              <a target="_blank" href="https://dropbox.com">
                {t.menu.image_bank} <ExternalSVG />
              </a>
            </li>
            <li className="fs--base lh--m">
              <a target="_blank" href="https://www.instagram.com/anddrape/">
                Instagram <ExternalSVG />
              </a>
            </li>
          </ul>
        </Wrapper>
      )}
    </nav>
  )
}

export default PushNav
